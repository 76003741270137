import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
const Index = () => {
    const navigate = useNavigate()
    useEffect(() => {
        navigate('/vi-VN')
    }, []);
    return (
        <>
            
        </>
    );
}

export default Index;
